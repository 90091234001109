import React, { useState } from 'react'
import * as XLSX from 'xlsx';
import { useParams } from "react-router-dom";
import { useDispatch } from 'react-redux'
import Swal from "sweetalert";
import { mass_responses_checker } from '../../../../../../actions/activities_reply_actions';

export default function Responses_checker() {

    const [columns, setColumns] = useState(null);
    const params = useParams()
    const dispatch = useDispatch()


    const handleFileUpload = (e) => {
        const file = e.target.files[0]; // Get the uploaded file
        if (!file) return;

        let fileName = file.name
        const reader = new FileReader();


        reader.onload = (event) => {
            const binaryString = event.target.result;


            const workbook = XLSX.read(binaryString, { type: 'binary' });


            const sheetName = workbook.SheetNames[0];
            const worksheet = workbook.Sheets[sheetName];


            const jsonData = XLSX.utils.sheet_to_json(worksheet);


            const columnNames = Object.keys(jsonData[0]);


            const extractedColumns = columnNames.reduce((acc, columnName) => {
                acc[columnName] = jsonData.map((row) => row[columnName]);
                return acc;
            }, {});

            extractedColumns.fileTitle = fileName
            Swal({
                title: "Please wait!",
                text: "Evaluating responses...",
                type: "info",
                buttons: false,
                closeModal: false,
                closeOnClickOutside: false
            });
            dispatch(mass_responses_checker(extractedColumns, params.activityid))
                .then((response) => {


                    const url = response.payload.content.link;

                    const url2 = url.replace(/(?<!D):\\/g, (match) => match.replace(/\\/g, ''));

                    const link = document.createElement('a');
                    link.href = url2;
                    link.download = response.payload.content.fileName;
                    document.body.appendChild(link);


                    link.click();


                    document.body.removeChild(link);
                    Swal.stopLoading()
                    Swal.close()
                })
                .catch(err => {
                    Swal.stopLoading()
                    Swal.close()
                    Swal({
                        title: "Error",
                        text: "Please try again",
                        type: "error"
                    })
                })

        };

        reader.readAsBinaryString(file);
    };

    return (
        <div style={{ height: '200px' }}>
            <div className='text-center'>
                <h4 className='my-4'>Upload an  excel file</h4>
                <p className='my-4'>File should only contains one sheet having one column named Email</p>
                <input type="file" accept=".xlsx, .xls" onChange={handleFileUpload} />
                {columns && (
                    <div style={{ marginTop: '20px' }}>


                    </div>
                )}
            </div>
        </div>
    )
}