import React from 'react';
import styles from '../../../assets/css/styling.module.css';
import styles3 from '../../../assets/css/newStyling.module.css';
import { TransitionGroup } from 'react-transition-group';
import ResizeDetector from "react-resize-detector";
import LoadingOverlay from 'react-loading-overlay-ts';
import Loader from "react-loaders";
import { useState, useEffect } from 'react';
import referImage from '../../../assets/images/refer_image.png'
import pointsBadge from '../../../assets/images/medal.png'
import { Line} from 'rc-progress';
import SecureLS from 'secure-ls';
import { useDispatch } from 'react-redux';
import Swal from 'sweetalert'
import { getUserDetails } from '../../../actions/user_action';
import { respondentDashboardDetails } from '../../../actions/dashboard_activities';


function ConnectPoints() {
    const [isLoading, setLoading] = useState(false)
    const dispatch = useDispatch()
const [profileCompletion, setProfileCompletion] = useState(0)
const [points, setPoints]=useState(0)
const [referCode, setReferCode] = useState('')
const [copied, setCopied] = useState(false);
const ls = new SecureLS()
const userId = ls.get('accessUser').user._id

    useEffect(() => {


        // getting user and dashboard details
        dispatch(getUserDetails(userId))
            .then(response => {
              
setReferCode(response.payload.content.referralCode)

                dispatch(respondentDashboardDetails())
                    .then(response2 => {
                     
                      setProfileCompletion(response2.payload.content.profileCompletion)
                      setPoints(response2.payload.content.totalPointsEarned)
                        //setUserPoints(response2.payload.content)
                       
                        //setLoading(false)
                    })
                    .catch(err => {
                        //setLoading(false)
                        Swal({
                            title: err.response ? err.response.data.name : "Error",
                            text: err.response ? err.response.data.content : "Something went wrong",
                            icon: 'error'
                        })
                    })

                //setUserDetails(response.payload.content)
               // getProjectListing(1, 3)
                //getSurvey(1, true)
                // setLoading(false)
            })
            .catch(err => {
                //setLoading(false)
                Swal({
                    title: err.response ? err.response.data.name : "Error",
                    text: err.response ? err.response.data.content : "Something went wrong",
                    icon: 'error'
                })
            })
    }, []);

   const handleLinkClick=()=>{

    let link = `https://app.consumerconnect.net/choose?code=${referCode}`
    const textArea = document.createElement('textarea');
    textArea.value = link;
    document.body.appendChild(textArea);
    textArea.select();
    document.execCommand('copy');
    document.body.removeChild(textArea);

    setCopied(true)
   
        Swal({
            title: 'Link Copied',
            icon: 'success'
        })
    

   }

    return (
        <TransitionGroup >

            <ResizeDetector
                handleWidth
                render={({ width }) => (

                    <LoadingOverlay tag="div" active={isLoading}
                        styles={{
                            overlay: (base) => ({
                                ...base,
                                background: "#fff",
                                opacity: 0.5,
                                position: 'fixed',
                                top: '20%'
                            }),
                        }}
                        spinner={
                            <Loader color="#6c757d" active type="ball-pulse" />
                        }>

                        <React.Fragment>










                            <div className="row" style={{marginTop:window.innerWidth > 768 ?'50px':'25px'}}>

                                <div className='col-12'>
                                    {/* connect points block */}
                                    <div className={styles.card_border}>



                                        <div className={styles.card_border_body + " pt-2 pb-3 px-0"}>
                                            <div style={{ height: '6rem' }}>
                                                <div className='d-flex' style={{display:'flex', alignItems:'flex-end', alignContent:'flex-end', marginRight:'20px'}}>
                                                    <p className={`${styles3.points} container pt-2`} style={{margin:'0px'}}>{points}</p>
                                                    <p className= {`${styles3.connect_rewards} pb-3`} style={{minWidth:'60%', margin:'0px'}}>Connect Points</p>
                                                    {/* <p className='pt-4' >Badge logo</p> */}
                                                    <img src={pointsBadge} alt='image' className={`${styles3.badge}`}></img>


                                                </div>
                                                {/* <p className='container'>progress bar</p> */}
                                                <div className='d-flex'>
                                               <span> <Line className='container' percent={profileCompletion}  strokeWidth={3} trailWidth={3} trailColor='#C1E4B5' strokeColor="#47B221" /></span>
                                                <span  className={window.innerWidth <768 ?'pl-4':''} style={{color: '#ADA7A7',
                                                fontSize: '15px', fontStyle: 'normal', fontWeight: '500'}}>{profileCompletion ? profileCompletion: '0'}%</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {/* refer banner */}
                                    <div className='pt-4'>
                                        <div className={styles3.card_border_borderless} style={{ backgroundColor: '#E6D2FF' }}>




                                            <div className={styles.card_border_body + " pt-2 pb-3 px-0"} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', cursor:'pointer'}} onClick={handleLinkClick} >
                                                <div style={{ height: '6rem', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                                    <p className={`${styles3.refer_block_text} container`} style={{ flex: '50%' }}>Earn money by referring people around</p>
                                                    <img src={referImage} alt='image' style={{ flex: '50%', maxHeight: '100%', maxWidth: '25%' }}></img>
                                                </div>
                                              

                                        </div>
                                    </div>
                                     
                                      </div>
                                </div>



                            </div>












                        </React.Fragment>


                    </LoadingOverlay>

                )}

            />
        </TransitionGroup>
    )

}

export default ConnectPoints;