import axios from 'axios';
import { BACK_SERVER_URL } from '../config/server';
import { AVAILABLE_USERS, CHANGE_PASSWORD, FIND_USER, GET_USER_DETAILS, UPDATE_USER, GET_PP, PICTURE_USER } from './types';

// Used in ADMIN END in Users Management Screen to display listing  
// Returns an array containing all users
export const getAllUsers = (page, limit, searched) => {

    let url = `${BACK_SERVER_URL}api/users?page=${page}&limit=${limit}&searched=${searched}`;

    const request = axios.get(url)
        .then(response => response.data);
        
      
    return {
        type: AVAILABLE_USERS,
        payload: request
    }
    
    
}


// ************** Below Apis used for all types of users ********************

// Used to search user with username in db based on search string user enters in dropdown of project participants and user chats
// Takes an object containing search string and user role ( no user role if you want to search string in all 3 types of user ) as param
// Returns an array of filtered users 
export const findUser = (data) => {

    let url = `${BACK_SERVER_URL}api/users/findUser`;

    const request = axios.post(url, data)
        .then(response => response.data);

    return {
        type: FIND_USER,
        payload: request
    }
}

// Returns user details against the id pass as param
export const getUserDetails = (userId) => {

    let url = `${BACK_SERVER_URL}api/users/${userId}`;

    const request = axios.get(url)
        .then(response => response.data);

    return {
        type: GET_USER_DETAILS,
        payload: request
    }
}

// used in profile section of users to update their details
export const editUserDetails = (userId, userDetails) => {

    let url = `${BACK_SERVER_URL}api/users/${userId}`;

    const request = axios.post(url, userDetails)
        .then(response => response.data);

    return {
        type: UPDATE_USER,
        payload: request
    }
}

export const newUserDetails = (userId, userDetails) => {
   
        let url = `${BACK_SERVER_URL}api/users/new/${userId}`;
    
        const request = axios.post(url, userDetails)
            .then(response => response.data);
    
        return {
            type: UPDATE_USER,
            payload: request
        }
    }

   

export const uploadUserPP = (userId, userDetails) => {
    console.log('user picture in frontend dispatch->', userDetails)
        let url = `${BACK_SERVER_URL}api/users/changeProfile/${userId}`;
    
        const request = axios.post(url, userDetails)
            .then(response => response.data);
    
        return {
            type: PICTURE_USER,
            payload: request
        }
    }

    
// edit user profile picture
export const editUserDetailsPP = (userId, userDetails) => {

    let url = `${BACK_SERVER_URL}api/users/pp/${userId}`;

    const request = axios.post(url, userDetails)
        .then(response => response.data);

    return {
        type: UPDATE_USER,
        payload: request
    }
}

export const changePassword = (userDetails) => {

    let url = `${BACK_SERVER_URL}api/users/resetPassword`;

    const request = axios.put(url, userDetails)
        .then(response => response.data);

    return {
        type: CHANGE_PASSWORD,
        payload: request
    }
}

// Used in RESPONDENT Dashboard to change user's profile image
// export const changeProfileImage = (userDetails) => {

//     let url = `${BACK_SERVER_URL}api/users/changeProfile`;

//     const request = axios.post(url, userDetails)
//         .then(response => response.data);

//     return {
//         type: CHANGE_PROFILE_IMAGE,
//         payload: request
//     }
// }

export const profilePicture =(userId)=>{
    let url = `${BACK_SERVER_URL}api/users/${userId}/profilepicture`;

    const request = axios.get(url)
        .then(response => response.data);
        

    return {
        type: GET_PP,
        payload: request
    }

}



