export const LOADING_START = 'loading_start';
export const LOADING_STOP = 'loading_stop';

export const LOGIN_USER = 'login_user';
export const LOGGED_IN_USER_DATA = 'logged_in_user_data';
export const SET_PROFILE = 'set_profile';
export const GET_PP = 'get_user_pp';
export const USER_SIGNUP = 'user_signup';
export const FORGET_PASSWORD = 'forget_password';
export const REFRESH_TOKEN = 'refresh_token';
export const UPDATE_LOGS = 'update_logs';

export const FIND_USER = 'find_user';
export const RESEARCHER_INVITE = 'researcher_invite';
export const AVAILABLE_USERS = 'available_users';
export const GET_USER_DETAILS = 'get_user_details';
export const UPDATE_USER = 'update_user';
export const CHANGE_PASSWORD = 'change_password';
export const CHANGE_PROFILE_IMAGE = 'change_profile_image';
export const PICTURE_USER = 'new_profile_image'
export const USER_RESEARCH_HISTORY = 'user_research_history';

export const CREATE_PROJECT = 'create_project';
export const GET_PROJECT_DETAILS = 'get_project_details';
export const DEL_PROJECT = 'del_project';
export const UPDATE_PROJECT = 'update_project'
export const EMPTY_PROJECT = 'empty_project';
export const PROJECT_AVAILABLE = 'project_available';
export const GET_ACTIVE_PROJECTS = 'get_active_projects';
export const PROJECT_INTRODUCTION = 'project_introduction';
export const ADD_PROJECT_INTRO = 'add_project_intro';
export const EDIT_PROJECT_INTRO = 'edit_project_intro';
export const PARTICIPANTS_PROJECTS = 'participants_projects';
export const PARTICIPANTS_PROJECTS_COMPLETION_PERCENTAGE = 'participants_projects_completion_percentage'
export const PROJECT_SCREENER_ATTACHMENTS = 'project_screener_attachment';
export const PROJECT_ATTACHMENTS = 'project_attachments';
export const PROJECT_SCREENER_REPLY_ATTACHMENTS = 'project_screener_reply_attachments';
export const PROJECT_RECORDINGS_ATTACHMENTS = 'project_recording_attachments';
export const EDIT_PROJECT_STATUS = 'edit_project_status';
export const PROJECT_PARTICIPANTS_REPORT = 'project_participants_report';
export const PROJECT_ACTIVITIES_REPORT = 'project_activities_report';
export const PROJECT_EXCERPTS_REPORT = 'project_excerpts_report';
export const PROJECT_REPORT = 'project_report';
export const SEARCH_PROJECT_WORD_CLOUD = 'search_project_word_Cloud';
export const SEARCH_PROJECT_POSTS = 'search_project_posts';
export const SEARCH_PROJECT_COMMENTS = 'search_project_comments';
export const SEARCH_PROJECT_POST_ATTACHMENTS = 'search_project_post_attachments';

export const INVITE_PARTICIPANT = 'invite_participant';
export const PARTICIPANT_AVAILABLE = 'participant_available';
export const PROJECT_PARTICIPANTS = 'project_participants';
export const REMOVE_PARTICIPANT = 'remove_participant';
export const ACCEPT_INVITE_NOTIFICATION = 'accept_invite_notification';
export const REJECT_INVITE_NOTIFICATION = 'reject_invite_notification';

export const GET_REFERRAL = 'get_referral';
export const EMPTY_REFERRAL = 'empty_referral';
export const REFERRAL_AVAILABLE = 'referral_available';
export const REFER_FRIEND = 'refer_friend'
export const AVAILABLE_USER_REFERRALS = 'available_user_referrals'

export const EMPTY_EMAIL_TEMPLATE = 'empty_email_template';
export const EMAIL_TEMPLATE_AVAILABLE = 'email_template_available';
export const CREATE_EMAIL_TEMPLATE = 'create_email_template';
export const GET_EMAIL_TEMPLATE_DETAILS = 'get_email_template_details';
export const EDIT_EMAIL_TEMPLATE_DETAILS = 'edit_email_template_details'
export const DELETE_EMAIL_TEMPLATE = 'del_email_template'
export const GET_USER_EMAIL_TEMPLATES_TITLE = 'get_user_email_template_title'

export const EMPTY_SMS_TEMPLATE = 'empty_sms_template';
export const SMS_TEMPLATE_AVAILABLE = 'sms_template_available';
export const CREATE_SMS_TEMPLATE = 'create_sms_template';
export const GET_SMS_TEMPLATE_DETAILS = 'get_sms_template_details';
export const DELETE_SMS_TEMPLATE = 'del_sms_template'
export const EDIT_SMS_TEMPLATE_DETAILS = 'edit_sms_template_details'
export const GET_USER_SMS_TEMPLATES_TITLE = 'get_user_sms_template_title'

export const EMPTY_BROADCAST_MSG = 'empty_broadcast_msg';
export const BROADCAST_MSG_AVAILABLE = 'broadcast_msg_available';
export const CREATE_SEND_BROADCAST_MSG = 'create_send_broadcast_msg';
export const GET_BROADCAST_MSG_DETAILS = 'get_broadcast_msg_details';

export const CREATE_COUNTRIES = 'create_countries';
export const CITY_OPTIONS = 'get_city_options';
export const INCOME_OPTIONS = 'get_income_options';
export const CREATE_CITIES = 'create_cities';
export const CREATE_SENIORITIES = 'create_seniorities';
export const CREATE_EDUCATION_LEVEL = 'create_education';
export const CREATE_INCOME_RANGES = 'create_income_ranges';
export const CREATE_EMPLOYMENT = 'create_employment';
export const CREATE_MARITAL_STATUS = 'create_marital_status';
export const CREATE_TEMPLATE_VARIABLE = 'create_template_var';
export const CREATE_POINTS_SYSTEM = 'create_points_system';
export const DELETE_POINTS_SYSTEM = 'delete_points_system';
export const CREATE_BRANDS = 'create_brands';
export const CREATE_INTERESTS = 'create_interests';
export const CREATE_RELATIONSHIPS = 'create_relationships';
export const GET_INTERESTS = 'get_interests';
export const GET_SPECIFIC_OPTION = 'get_specific_options'
export const GET_ALL_OPTIONS = 'get_all_options'

export const AUDIENCE_AVAILABLE = 'audience_available';
export const PROJECT_AUDIENCE_AVAILABLE = 'project_audience_available';
export const CREATE_AUDIENCE = 'create_audience';
export const GET_AUDIENCE_DETAILS = 'get_audience_details';
export const MOVE_REMOVE_AUDIENCE_MEMBERS = 'move_remove_audience_member';
export const EDIT_AUDIENCE = 'edit_audience';

export const CAMPAIGN_AVAILABLE = 'campaign_available';
export const CREATE_CAMPAIGN = 'create_campaign';
export const GET_CAMPAIGN = 'get_campaign';
export const DELETE_CAMPAIGN = 'delete_campaign';
export const GET_CAMPAIGN_DETAIL = 'get_campaign_detail';
export const EDIT_CAMPAIGN = 'edit_campaign';

export const MY_NOTIFICATIONS = 'my_notifications';

export const CREATE_ACTIVITY = 'create_activity';
export const GET_ACTIVITY = 'get_activity';
export const AVAILABLE_ACTIVITY = 'available_activity';
export const AVAILABLE_SCREENER = 'available_screener';
export const AVAILABLE_QUICK_SURVEY = 'available_quick_survey';
export const UPDATE_ACTIVITY = 'update_activity';
export const DELETE_ACTIVITY = 'delete_activity';
export const AVAILABLE_RESPONDENT_ACTIVITY = 'available_respondent_activity';
export const AVAILABLE_RESPONDENT_FORUM = 'available_respondent_forum';
export const AVAILABLE_GENERAL_FORUM = 'available_general_forum';
export const RESPONDENT_ACTIVITY_DETAILS = 'respondent_activity_details';
export const PROJECT_ALL_ACTIVITIES = 'project_all_activity';

export const AVAILABLE_ACTIVITY_WORD_CLOUD = 'available_activity_word_cloud';
export const AVAILABLE_ACTIVITY_ATTACHMENTS = 'available_activity_attachments';
export const ACTIVITY_REPLY_GRAPHS = 'activity_reply_graphs';
export const ACTIVITY_REPLY_GROUPINGS = 'activity_reply_groupings';

export const CREATE_ACTIVITY_MEETING = 'create_activity_meeting';
export const GET_MEETING_DETAILS = 'get_meeting_details';
export const DELETE_MEETING = 'delete_meeting';
export const EDIT_MEETING = 'edit_meeting';
export const AVAILABLE_ACTIVITY_MEETINGS = 'available_activity_meetings';

export const CREATE_POST_REPLY = 'create_post_reply';
export const AVAILABLE_POST_REPLY = 'available_post_reply';
export const EDIT_POST_REPLY = 'edit_post_reply';
export const DELETE_POST_REPLY = 'delete_post_reply';
export const POST_REPORTS = 'post_report';
export const EXPORT_POST_RESPONSES = 'export_post_responses';

export const CREATE_SURVEY_REPLY = 'create_survey_reply';
export const GET_SURVEY_REPLY = 'get_survey_reply'
export const EDIT_SURVEY_REPLY = 'edit_survey_reply'
export const AVAILABLE_SURVEY_REPLY = 'available_survey_reply'
export const ACTIVITY_POINTS_CALCULATION = 'activity_points_calculation'
export const DELETE_SURVEY_REPLY = 'delete_survey_reply'
export const SURVEY_REPORTS = 'survey_reports';
export const EXPORT_SURVEY_RESPONSES = 'export_survey_responses';
export const SEND_REPLIED_ANSWER = 'send_replied_answer';
export const MASS_REPLIED_CHECKER = 'mass_replied_checker';

export const CREATE_COMMENTS = 'create_comments';
export const AVAILABLE_POST_COMMENTS = 'available_post_comments';
export const EDIT_COMMENT = 'edit_comment';
export const DELETE_COMMENT = 'delete_comment';
export const DELETE_SURVEY_ONE_REPLY = 'delete_survey_one_reply';

export const AVAILABLE_PARTICIPANTS_POST = 'available_participants_post';
export const AVAILABLE_SURVEY_PARTICIPANTS = 'available_survey_participants';
export const AVAILABLE_POST_PARTICIPANTS = 'available_post_participants';
export const AVAILABLE_PARTICIPANTS_REPLY_STREAM = 'available_participants_post_stream';
export const JOIN_PROJECT = 'join_project';

export const CREATE_EXCERPTS = 'create_excerpts';
export const AVAILABLE_ACTIVITY_EXCERPTS = 'available_activity_excerpts';
export const EDIT_EXCERPT = 'edit_excerpt';
export const DELETE_EXCERPT = 'delete_excerpt';
export const GET_EXCERPT_DETAILS = 'get_excerpt_details';

export const CREATE_CHATROOM = 'create_chatroom';
export const GET_CHATROOM = 'get_chatroom';
export const EDIT_CHATROOM = 'edit_chatroom';
export const DELETE_CHATROOM = 'delete_chatroom';
export const GET_ALL_USER_CHATROOM = 'get_all_user_chatroom';
export const GET_CHAT_HISTORY = 'get_chat_history';
export const AVAILABLE_CHAT_HISTORY = 'available_Chat_history'

export const CREATE_RECORDING = 'create_recording';
export const AVAILABLE_ACTIVITY_RECORDINGS = 'available_activity_recordings';
export const EDIT_RECORDING = 'edit_recording';
export const DELETE_RECORDING = 'delete_recording';
export const GET_RECORDING_DETAILS = 'get_recording_Details';

export const ACTIVE_PROJECTS_FOR_DASHBOARD = 'active_projects_for_dashboard';
export const ADMIN_DASHBOARD = 'admin_dashboard';
export const RESPONDENT_DASHBOARD = 'respondent_dashboard';
export const RESPONDENT_TASKS = 'respondent_tasks';
export const GET_REPLIES_IMAGES = 'get_reply_images';
export const CREATE_CATEGORIES = 'create_categories';
export const GET_CATEGORIES = 'get_categories';
export const SCRIPT_CATEGORIES = 'script_categories';
export const CATEGORIZE_ACTIVITY = 'categorize_activity'