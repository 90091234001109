import React, { useState, useEffect } from 'react'
import { Card, Modal, ModalBody, ModalHeader, Button } from "reactstrap";
import bg2 from "../../../../assets/utils/images/dropdown-header/city2.jpg";
import styles from '../../../../assets/css/rstyling.module.css'
import Tabs, { TabPane } from "rc-tabs";
import TabContent from "rc-tabs/lib/SwipeableTabContent";
import ScrollableInkTabBar from "rc-tabs/lib/ScrollableInkTabBar";
import Search from '../../../../assets/images/search_icon.png'
import { TransitionGroup } from 'react-transition-group';
import { useNavigate, useLocation, useParams } from "react-router-dom";
import ResizeDetector from "react-resize-detector";
import PageHeader from '../../../../assets/components/pageheader/PageHeader';
import Projecticon from '../../../../assets/images/project_header_icon.png'
import ArrowLeft from "../../../../assets/images/arrow_left.png";
import Excerpts from '../../GeneralForums/view/Excerpts';
import MiniSurveyParticipant from './MiniSurveyParticipant';
import Scripting from './Scripting'
import Responses_checker from './Responses_checker';

// ********** This component is called on the click of view icon in survey listing ****************

const MiniSurveyActivity = (props) => {

    const history = useNavigate()
    const location = useLocation();
const currentPath = location.pathname;
    const params = useParams()
    let { search } = useLocation();
    const query = new URLSearchParams(search);
    const pathWithId = currentPath.slice(0, currentPath.lastIndexOf('/'))
    const path = pathWithId.slice(0, pathWithId.lastIndexOf('/'))

    // Card Header
    const getHeader = () => {
        return <div className="form-inline my-2 my-sm-0 " style={{ marginRight: '-2.3rem' }}>
            <div className="input-group input-group-sm w-75">
                <div className="input-group-prepend border-0">
                    <span className={`bg-white my-sm-0 input-group-text form-control-sm ${styles.search_input}`}> <img src={Search} alt="search" /></span>
                </div>
                <input className={`form-control input-sm mr-sm-2 p-0 m-0 border-left-0 form-control-sm ${styles.search_input}`} type="search" placeholder="Search" aria-label="Search" />
            </div>
        </div>
    }

    return (

        <TransitionGroup component="div"transitionName="TabsAnimation" transitionAppear={true}
            transitionAppearTimeout={0} transitionEnter={false} transitionLeave={false}>

            <PageHeader
                heading="Quick Survey" img={Projecticon}
            />

            {/* Back Icon */}
            <span onClick={() => history(-1)}  style={{ cursor: 'pointer' }}>
                <img src={ArrowLeft} alt="" width="8px" />
                <label className="ml-2" style={{ cursor: 'pointer' }}>Back</label>
            </span>

            <ResizeDetector
                handleWidth
                render={({ width }) => (
                    <div className="card_radius">
                        <Card className="mb-0 mt-3">
                            <div className="dropdown-menu-header">
                                <div className="dropdown-menu-header-inner bg-dark">
                                    <div className="menu-header-image"
                                        style={{
                                            backgroundImage: "url(" + bg2 + ")",
                                        }}>
                                    </div>
                                    {/* table header */}
                                    <div className="menu-header-content" style={{ display: 'flex' }}>
                                        <div className="menu-header-content-left mr-auto">
                                            <div className="menu-header-title text-white text-left" style={{ fontWeight: '500' }}>Quick Survey</div>
                                        </div>

                                        <div className="menu-header-content-right">
                                            {/* Card Header */}
                                            {getHeader()}
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {/* View Tabs */}
                            <div className={width >= 708 ? "tab_blue tab_center" : "tab_blue"}>

                                <Tabs defaultActiveKey={query.get("tab")} onChange={(key) => key === '3' &&   history('/admin/preview-minisurvey/' + params.activityid)}
                                    renderTabBar={() => <ScrollableInkTabBar color="primary" />}
                                    renderTabContent={() => <TabContent />} justified  >

                                    <TabPane tab="Participant" key="1">
                                        <MiniSurveyParticipant />
                                    </TabPane>

                                    <TabPane tab="Excerpts" key="2" >
                                        <Excerpts />
                                    </TabPane>

                                    <TabPane tab="View Form" key="3">
                                    </TabPane>
                                    <TabPane tab="Form Scripting" key="0" >
                                    <Scripting/>
                                    </TabPane>
                                    <TabPane tab="Responses Evaluation" key="4" >
                                    <Responses_checker/>
                                    </TabPane>

                                </Tabs>

                            </div>


                        </Card>

                    </div>
                )}

            />
        </TransitionGroup>


    )
}

export default MiniSurveyActivity
