import React from "react";
import { Button } from "reactstrap";
import Swal from 'sweetalert'
import styles from '../../../assets/css/styling.module.css';
import { FaArrowRight, FaArrowLeft } from "react-icons/fa6";
import { BACK_SERVER_URL } from "../../../config/server"
import axios from 'axios'
const getNavStates = (indx, length) => {
	let styles = [];
	
	for (let i = 0; i < length; i++) {
		if (i < indx) {
			
			styles.push("done");
		} else if (i === indx) {
		
			styles.push("doing");
		} else {
			
			styles.push("todo");
		}
	}
	return { current: indx, styles: styles };
};

const checkNavState = (currentStep, stepsLength) => {
	
	if (currentStep > 0 && currentStep <= stepsLength - 1) {
		return {
			showPreviousBtn: true,
			showNextBtn: true,
			showSubmitBtn: false
		};
	} else if (currentStep === 0) {
		return {
			showPreviousBtn: false,
			showNextBtn: true,
			showSubmitBtn: false
		};
	} else if (currentStep === stepsLength) {
		return {
			showPreviousBtn: true,
			showNextBtn: false,
			showSubmitBtn: true
		};
	} else {
		return {
			showPreviousBtn: true,
			showNextBtn: false,
			showSubmitBtn: false
		};
	}
};

export default class Survey_Steps extends React.Component {

	state = {
		showPreviousBtn: false,
		showNextBtn: true,
		showSubmitBtn: false,
		compState: this.props.initialStep && this.props.initialStep && this.props.initialStep!=undefined ? this.props.initialStep : 0,
		navState: getNavStates(this.props.initialStep && this.props.initialStep && this.props.initialStep!=undefined ? this.props.initialStep : 0, this.props.steps.length),
	};

	setNavState = (next) => {


		



		this.setState({
			navState: getNavStates(next, this.props.steps.length),
		});
		if (next < this.props.steps.length) {
			
			this.setState({ compState: next });
		}
		
		this.setState(checkNavState(next, this.props.steps.length));
	};


	next = () => {

		let notCompleted = false;
		const questions = this.props.steps[this.state.compState].page.questions;

		// Set Current Page
		this.props.steps[this.state.compState].setCurrentPage(this.state.compState + 1);
		// Check Requried question validation
		for (let i = 0; i < questions.length; i++) {

			if (questions[i].required) {

				if (questions[i].questionType === 'radio' && (!questions[i].selected || questions[i].selected === '')) {
					notCompleted = true;
					break;
				}

				else if (questions[i].questionType === 'checkbox' && (!questions[i].selected || questions[i].selected.length === 0)) {
					notCompleted = true;
					break;
				}

				else if (questions[i].questionType === 'grid' && (!questions[i].selected || questions[i].selected.length < questions[i].rows.length)) {
					notCompleted = true;
					break;
				}

				else if (questions[i].questionType === 'long text' && (!questions[i].answer || questions[i].answer === '')) {
					
					notCompleted = true;
					break;
				}

				else if (questions[i].questionType === 'image' && (!questions[i].image || questions[i].image.length === 0)) {
					notCompleted = true;
					break;
				}

				else if (questions[i].questionType === 'video' && (!questions[i].video || questions[i].video.length === 0)) {
					notCompleted = true;
					break;
				}

				else if (questions[i].questionType === 'image text' && (!questions[i].image || (questions[i].image.length === 0 || questions[i].answer === ''))) {
					notCompleted = true;
					break;
				}

				else if (questions[i].questionType === 'video text' && (!questions[i].video || (questions[i].video.length === 0 || questions[i].answer === ''))) {
					notCompleted = true;
					break;
				}

				else if (questions[i].questionType === 'spectrum' && (!questions[i].selected || questions[i].selected === '')) {
					notCompleted = true;
					break;
				}

				else {
					notCompleted = false;
				}

			}

		}

		// check validtion passed then proceed next step
		// setTimeout(() => {
			
		if (notCompleted === false) {
			
			this.setNavState(this.state.compState + 1);

			if (this.state.showSubmitBtn)
				this.props.onSubmit()

		}
		// }, 1000);



	};

	pointsCalculation = () =>{
let activity =  this.props.activityId
		let url = `${BACK_SERVER_URL}api/replies/activityPoints/${activity}`;

		const request = axios.get(url)
			.then(response => response.data);
	}
	componentDidMount(){
	
		
		if(this.props.initialStep && this.props.initialStep && this.props.initialStep!=undefined){
			 if(typeof this.props.initialStep ==='string'){

				this.setNavState(parseInt(this.props.initialStep, 10))
			
			 }
		
		
		}
	}
 

	previous = () => {
		if (this.state.compState > 0) {

			// Set Current Page
			this.props.steps[this.state.compState].setCurrentPage(this.state.compState - 1);
			this.setNavState(this.state.compState - 1);
		}
	};
	saveNotificationFunction = () => {
		let notCompleted = false;
		const questions = this.props.steps[this.state.compState].page.questions;

		// Set Current Page
		this.props.steps[this.state.compState].setCurrentPage(this.state.compState + 1);
		// Check Requried question validation
		for (let i = 0; i < questions.length; i++) {

			if (questions[i].required) {

				if (questions[i].questionType === 'radio' && (!questions[i].selected || questions[i].selected === '')) {
					notCompleted = true;
					break;
				}

				else if (questions[i].questionType === 'checkbox' && (!questions[i].selected || questions[i].selected.length === 0)) {
					notCompleted = true;
					break;
				}

				else if (questions[i].questionType === 'grid' && (!questions[i].selected || questions[i].selected.length < questions[i].rows.length)) {
					notCompleted = true;
					break;
				}

				else if (questions[i].questionType === 'long text' && (!questions[i].answer || questions[i].answer === '')) {
					notCompleted = true;
					break;
				}

				else if (questions[i].questionType === 'image' && (!questions[i].image || questions[i].image.length === 0)) {
					notCompleted = true;
					break;
				}

				else if (questions[i].questionType === 'video' && (!questions[i].video || questions[i].video.length === 0)) {
				
					notCompleted = true;
					break;
				}

				else if (questions[i].questionType === 'image text' && (!questions[i].image || (questions[i].image.length === 0 || questions[i].answer === ''))) {
					notCompleted = true;
					break;
				}

				else if (questions[i].questionType === 'video text' && (!questions[i].video || (questions[i].video.length === 0 || questions[i].answer === ''))) {
					notCompleted = true;
					break;
				}

				else if (questions[i].questionType === 'spectrum' && (!questions[i].selected || questions[i].selected === '')) {
					notCompleted = true;
					break;
				}

				else {
					notCompleted = false;
				}

			}

		}
		if (notCompleted === false) {
			//	this.setNavState(this.state.compState + 1);

			Swal({
				title: "Saved",
				text: "Responses are saved",
				icon: 'success'
			})
		}
	}
	getClassName = (className, i) => {
		return className + "-" + this.state.navState.styles[i];
	};

	renderSteps = () => {
		return this.props.steps.map((s, i) => (
			<li
				className={`${this.getClassName("form-wizard-step", i)}`}
				key={i}
				value={i}
			>
			
				{/* page index  */}
				
				<em style={s.percentage > 99 ? { fontSize: '0.75rem' } : { fontSize: '0.9rem' }}>{s.percentage + "%"}</em>
			
				
				<span  style={{color:'#000', whiteSpace: 'normal', 
    overflow: 'hidden',
    textOverflow: 'ellipsis'}}>{this.props.steps[i].name}</span>
				
			</li>
		));
	};
	render() {
		return (
			<div className="_survey-steps_">

				{/* <div className="py-4">
					
					<h5 style={{ color: "#666", fontWeight: 600, fontSize: "1rem" }} className="mb-0">
						Questionaire
					</h5>
				</div> */}

				{/* Steps Strip */}
				<div className={window.innerWidth>768 ? `mx-5 px-5`:``}>
					<ol className="forms-wizard">{this.renderSteps()}</ol>
				</div>


				{/* Steps Content */}
				{this.props.steps[this.state.compState].component}

				{/* Buttons Prev and Next */}
				{this.props.showNavigation && (
					<div className="clearfix pt-3">

						{this.state.showNextBtn && (
							<div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>

								{/* <Button className={"btn-hover-shine px-5 py-2"} style={{ backgroundColor: '#0069AB', color: 'white', border: 'none' }} onClick={this.saveNotificationFunction}>
									Save Responses
								</Button> */}
							</div>
						)}
						{this.state.showSubmitBtn && (
							<div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
								<Button className={"float-right btn-hover-shine px-5 py-2"} style={{ backgroundColor: '#0069AB', color: 'white', border: 'none' }}  onClick={() => {this.next(); this.pointsCalculation(); 
  }}>
									Submit
								</Button>
							</div>
						)}
						{this.state.showNextBtn && (
							window.innerWidth > 768 ? (
								<Button
									className={"float-right btn-hover-shine px-4 py-2 mt-2"}
									style={{ backgroundColor: '#0069AB', color: 'white', border: 'none' }}
									onClick={this.next}
								>
									Next
								</Button>
							) : (
								<Button className={"float-right btn-hover-shine px-3 py-2 mt-2"} style={{ backgroundColor: '#0069AB', color: 'white', border: 'none' }} onClick={this.next} ><FaArrowRight /></Button>
							)
						)}

						{this.state.showPreviousBtn && (
							window.innerWidth > 768 ? (
								<Button className={"float-left btn-hover-shine px-5 py-2 mt-2"} style={{ backgroundColor: '#0069AB', color: 'white', border: 'none' }} onClick={this.previous}>
									Previous
								</Button>) : (<Button className={"float-left btn-hover-shine px-3 py-2 mt-2"} style={{ backgroundColor: '#0069AB', color: 'white', border: 'none' }} onClick={this.previous}><FaArrowLeft /></Button>)

						)}

					</div>
				)}

			</div>
		);
	}
}

Survey_Steps.defaultProps = {
	showNavigation: true,
};
