import React, { useState } from 'react'
import styles from '../../css/styling.module.css';
import { Droppable, Draggable } from "react-beautiful-dnd";
import DndIcon from '../../images/dnd_icon.png'
import CrossICon from '../../images/close_icon.png'
import AttachementIcon from '../../images/attachement_icon.png'
import ImageModal from '../modal/ImageModal';
import YouTube from 'react-youtube'
import Swal from 'sweetalert'
import { CKEditor } from '@ckeditor/ckeditor5-react';
import '@ckeditor/ckeditor5-build-classic/build/translations/ur';
//import ClassicEditor from '@ckeditor/ckeditor5-editor-classic/src/classiceditor';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
//import ClassicEditor from 'editor/build/ckeditor'





const Create_Question_With_Radiobtn = (props) => {

    const [isOtherShow, showOther] = useState(false)
    const [isModalOpen, setModal] = useState()
    const [isShowQuestionFileModal, setQuestionFileModal] = useState(false)
    const question = props.questionText
    const [typingTimer, setTypingTimer] = useState(null);
    // For changing text of a question
    const onQuestionTextChange = (value2) => {

        const value = value2.replace(/<p>/g, '<span>').replace(/<\/p>/g, '</span>');

        let pages = [...props.allContent.pages]

        pages[props.pageIndex].questions[props.questionIndex].questionText = value;

        props.setContent({
            ...props.allContent,
            pages: [...pages]
        })

    }
    // For embedding youtube video as a question
    const onSelectEmbedVideo = (value) => {

        let pages = [...props.allContent.pages]

        pages[props.pageIndex].questions[props.questionIndex].questionVideo = value;

        props.setContent({
            ...props.allContent,
            pages: [...pages]
        })
    }
    const opts = {
        width: '640',
        height: '360',
        playerVars: {
            autoplay: 0
        },
    };

    // For selecting question as required
    const onChangeRequired = () => {

        let pages = [...props.allContent.pages]

        pages[props.pageIndex].questions[props.questionIndex].required = !pages[props.pageIndex].questions[props.questionIndex].required;

        props.setContent({
            ...props.allContent,
            pages: [...pages]
        })
    }

     // For selecting question as sec
     const onChangeSEC = () => {

        let pages = [...props.allContent.pages]

        pages[props.pageIndex].questions[props.questionIndex].sec = !pages[props.pageIndex].questions[props.questionIndex].sec;

        props.setContent({
            ...props.allContent,
            pages: [...pages]
        })
    }

    // For setting question in random Order
    const onSelectRandomOrder = () => {

        let pages = [...props.allContent.pages]

        pages[props.pageIndex].questions[props.questionIndex].isRandomOrder = !pages[props.pageIndex].questions[props.questionIndex].isRandomOrder;

        props.setContent({
            ...props.allContent,
            pages: [...pages]
        })
    }

    // // For changing question image
    const onQuestionImageSelect = (file) => {
        const selectedFile = file[0]; // Assuming only a single file is allowed
        const maxSize = 25 * 1024 * 1024;
        if (selectedFile && selectedFile.size <= maxSize) {
            if (file && file[0]) {

                // File Extension Check
                let fileExtension = file[0].name.split('.').pop();

                if (fileExtension === "png" || fileExtension === "jpg" || fileExtension === "jpeg") {

                    // Set All Content
                    let pages = [...props.allContent.pages]

                    pages[props.pageIndex].questions[props.questionIndex].questionFile = file[0];
                    pages[props.pageIndex].questions[props.questionIndex].mediaType = fileExtension;

                    props.setContent({
                        ...props.allContent,
                        pages: [...pages]
                    })

                    // reader.readAsDataURL(file[0]);

                } else if (fileExtension === "mp4") {

                    // Set All Content
                    let pages = [...props.allContent.pages]

                    pages[props.pageIndex].questions[props.questionIndex].questionFile = file[0];
                    pages[props.pageIndex].questions[props.questionIndex].mediaType = fileExtension;

                    props.setContent({
                        ...props.allContent,
                        pages: [...pages]
                    })
                }

            }


        } else {
            Swal({
                title: "Error",
                text: "Video size must not exceed 25MB",
                icon: 'error'
            })
        }
    }

    // For deleting an Question File
    const removeQuestionFile = () => {

        let pages = [...props.allContent.pages]

        pages[props.pageIndex].questions[props.questionIndex].questionFile = "";

        props.setContent({
            ...props.allContent,
            pages: [...pages]
        })
    }

    // For selecting other in option
    const onChangeOthers = () => {

        let pages = [...props.allContent.pages]

        pages[props.pageIndex].questions[props.questionIndex].isOtherAllowed = !pages[props.pageIndex].questions[props.questionIndex].isOtherAllowed;

        props.setContent({
            ...props.allContent,
            pages: [...pages]
        })
        showOther(!isOtherShow)
    }

    // For changing text of options
    const onOptionTextChange = (value, optionIndex) => {
        let pages = [...props.allContent.pages]

        pages[props.pageIndex].questions[props.questionIndex].options[optionIndex] = value;

        props.setContent({
            ...props.allContent,
            pages: [...pages]
        })

    }

    // For adding a new option
    const addNewOption = () => {
        let pages = [...props.allContent.pages];
        pages[props.pageIndex].questions[props.questionIndex].options.push("")

        props.setContent({ ...props.allContent, pages });
    }

    // For deleting an option
    const delOption = (index) => {
        let pages = [...props.allContent.pages];
        pages[props.pageIndex].questions[props.questionIndex].options.splice(index, 1)

        props.setContent({ ...props.allContent, pages });
    }

    // For deleting a question
    const delQuestion = (index) => {
        let pages = [...props.allContent.pages];
        pages[props.pageIndex].questions.splice(index, 1)

        props.setContent({ ...props.allContent, pages });
    }

    const handleEditorChange = (event, editor) => {
        const newContent = editor.getData();
        
        clearTimeout(typingTimer);
        const newTypingTimer = setTimeout(() => {
            onQuestionTextChange(newContent);
        }, 2000);
        setTypingTimer(newTypingTimer)

    }


    return (
        <div className="my-0">
            <div className={`${styles.card_border} ${styles.question_card}`} style={!props.isValidQuestion ? { borderColor: '#d92550' } : {}}>

                <div className={styles.card_border_body + " pt-4 pb-1"}>


                    <div style={{ color: '#666', fontSize: "1rem", fontWeight: '500', whiteSpace: 'pre-wrap' }} className="mb-0">

                        <div className="d-flex">
                            <label className="mr-auto">Question # {props.questionNo}</label><br />
                            <button className="btn btn-light bg-white border-0 mb-1" height="2.5rem" onClick={() => delQuestion(props.questionIndex)} type="button">
                                <img src={CrossICon} alt="" width="15px" />
                            </button>
                            <span {...props.dragHandleProps} style={{ margin: '-1.5rem -3.3rem 0 0' }} className={styles.dnd_question}>
                                <img src={DndIcon} alt="" />
                            </span>
                        </div>
                        {/* Embedded YOutube video */}
                        <div>
                            <input type="text" placeholder="Write YouTube video ID here" value={props.questionVideo} className="form-control"
                                onChange={(e) => onSelectEmbedVideo(e.target.value)}
                            />

                            <YouTube videoId={props.questionVideo} opts={opts} className='py-4 text-center'></YouTube>


                        </div>
                        {/* Question text */}
                        {/* <input type="text" placeholder="Write Question here" value={props.questionText} className="form-control"
                            onChange={(e) => onQuestionTextChange(e.target.value)}
                        /> */}

                        <CKEditor
                            editor={ClassicEditor}
                            data={question}

                            onChange={handleEditorChange}




                        />

                    </div>


                    {/* Radio Button */}
                    <div className="mt-4 mx-1">

                        <Droppable droppableId={`droppable${props.questionIndex}`} type={`${props.questionIndex}`}>

                            {(provided, snapshot) => (
                                <div
                                    ref={provided.innerRef}
                                    className="row"
                                >
                                    <div className="col-md-6 col-sm-12 col-12 options">

                                        <label>Add File</label>
                                        <div className={`input-group mb-3 hide-file-text`}>
                                            <input type="file" className="pl-1 py-1 form-control" multiple={false} accept={".jpg, .jpeg, .png, .mp4"}
                                                style={{ color: '#666', fontSize: "0.96rem", fontWeight: '400', verticalAlign: '-6px', height: '2.5rem' }}
                                                onChange={(e) => onQuestionImageSelect(e.target.files)}
                                            />
                                            {
                                                props.questionFile ?
                                                    (typeof props.questionFile === 'string' ? props.questionFile.split('.').pop() === 'mp4' : props.questionFile.name.split('.').pop() === 'mp4') ?
                                                        <>
                                                            <div className="position-relative" onClick={() => setQuestionFileModal(!isShowQuestionFileModal)} style={{ cursor: 'pointer' }}>
                                                                {<p>Video Selected</p>}

                                                            </div>
                                                            <ImageModal
                                                                isModalOpen={isShowQuestionFileModal} setModal={() => setQuestionFileModal(!isShowQuestionFileModal)}
                                                                image={typeof props.questionFile === 'string' ? props.questionFile : URL.createObjectURL(props.questionFile)}
                                                                changeImage={onQuestionImageSelect}
                                                                type="video"
                                                                // index={index}
                                                                delImage={removeQuestionFile}
                                                            />
                                                        </>
                                                        :
                                                        <>
                                                            <img src={typeof props.questionFile === 'string' ? props.questionFile : URL.createObjectURL(props.questionFile)} alt="" width="40px" height="40px" onClick={() => setQuestionFileModal(!isShowQuestionFileModal)} />


                                                            <ImageModal
                                                                isModalOpen={isShowQuestionFileModal} setModal={() => setQuestionFileModal(!isShowQuestionFileModal)}
                                                                image={typeof props.questionFile === 'string' ? props.questionFile : URL.createObjectURL(props.questionFile)}
                                                                changeImage={onQuestionImageSelect}
                                                                // index={index}
                                                                delImage={removeQuestionFile}
                                                            />



                                                        </>

                                                    :
                                                    <label className="btn btn-light" style={{ padding: ' 0.53rem 0.6rem' }}>
                                                        <img src={AttachementIcon} alt="" width="20px" />
                                                    </label>
                                            }
                                        </div>


                                    </div>
                                    <div className="col-md-6 col-sm-12 col-12">
                                        <label>Answer Type</label>
                                        <input type="text" value={props.questionType} disabled className="form-control" />
                                    </div>

                                    <div className="col-12 mt-3">
                                        <label>Radio Button Options</label>
                                    </div>
                                    {props.options.map((option, index) => {

                                        return <div className="col-md-6 col-sm-12 col-12 options" key={`${props.questionIndex} ${index}`}><Draggable
                                            key={`${props.questionIndex} ${index}`}
                                            draggableId={`${props.questionIndex} ${index}`}
                                            index={index}

                                        >
                                            {(provided, snapshot) => (
                                                <div
                                                    ref={provided.innerRef}
                                                    {...provided.draggableProps}
                                                >

                                                    {/* Radio Buttons */}

                                                    <div className={`input-group mb-3 ${styles.option_input}`}>
                                                        <div className="input-group-prepend">
                                                            <span className="input-group-text bg-white border-right-0">
                                                                <span style={{ fontSize: "5px", paddingLeft: "5px", border: "1px solid #006FA7", borderRadius: "50%" }}>&nbsp;</span>
                                                            </span>
                                                        </div>
                                                        <input type="text" placeholder="Option" value={option} className="form-control"
                                                            style={{ color: '#666', fontSize: "0.96rem", fontWeight: '400', verticalAlign: '-6px', height: '2.5rem' }}
                                                            onChange={(e) => onOptionTextChange(e.target.value, index)}
                                                        />
                                                        <div className={`input-group-append`}>
                                                            <button className="btn btn-light" height="2.5rem" onClick={() => delOption(index)} type="button">
                                                                <img src={CrossICon} alt="" width="15px" />
                                                            </button>
                                                        </div>
                                                        <div className={`input-group-append ${styles.dnd_options}`}>
                                                            <span {...provided.dragHandleProps} >
                                                                <img src={DndIcon} alt="" width="40px" />
                                                            </span>
                                                        </div>
                                                    </div>


                                                </div>
                                            )}
                                        </Draggable>
                                        </div>

                                    })}

                                    {/* Others option */}
                                    <div className="col-md-6 col-sm-12 col-12">
                                        <div className={`input-group mb-3 ${styles.option_input}`} style={{ display: isOtherShow ? 'flex' : 'none' }}>
                                            <div className="input-group-prepend">
                                                <span className="input-group-text bg-white border-right-0">
                                                    <span style={{ fontSize: "5px", paddingLeft: "5px", border: "1px solid #006FA7" }}>&nbsp;</span>
                                                </span>
                                            </div>
                                            <input type="text" value="Others" className="form-control" readOnly
                                                style={{ color: '#666', fontSize: "0.96rem", fontWeight: '400', verticalAlign: '-6px', height: '2.5rem' }}
                                            // onChange={(e) => onOptionTextChange(e.target.value, index)}
                                            />
                                        </div>
                                    </div>

                                    {provided.placeholder}
                                </div>
                            )}
                        </Droppable>

                        <div className="row pl-3">

                            <div className="col-12">
                                <button className="btn btn-light rounded-circle float-right" onClick={() => addNewOption()} type="button">+</button>
                            </div>

                            {/* Required */}
                            <div className="custom-checkbox custom-control pb-4 col-md-3 col-6">
                                <input type="checkbox" id={`required ${props.questionIndex}`} className="custom-control-input" checked={props.required} onChange={() => onChangeRequired()} />
                                <label className="custom-control-label" htmlFor={`required ${props.questionIndex}`}>
                                    <span className="ml-3" style={{ color: '#666', fontSize: "0.96rem", fontWeight: '400', verticalAlign: '-6px' }}>Required</span>
                                </label>
                            </div>
                            {/* SEC */}
                            <div className="custom-checkbox custom-control pb-4 col-md-3 col-6">
                                <input type="checkbox" id={`sec ${props.questionIndex}`} className="custom-control-input" checked={props.sec} onChange={() => onChangeSEC()} />
                                <label className="custom-control-label" htmlFor={`sec ${props.questionIndex}`}>
                                    <span className="ml-3" style={{ color: '#666', fontSize: "0.96rem", fontWeight: '400', verticalAlign: '-6px' }}>SEC</span>
                                </label>
                            </div>

                            {/* Others Option */}
                            <div className="custom-checkbox custom-control pb-4 col-md-3 col-6">
                                <input type="checkbox" id={`othersOption ${props.questionIndex}`} className="custom-control-input" checked={props.isOtherAllowed} onChange={(e) => onChangeOthers()} />
                                <label className="custom-control-label" htmlFor={`othersOption ${props.questionIndex}`}>
                                    <span className="ml-3" style={{ color: '#666', fontSize: "0.96rem", fontWeight: '400', verticalAlign: '-6px' }}>Others Option</span>
                                </label>
                            </div>

                            {/* Random Order Selection */}
                            <div className="custom-checkbox custom-control pb-4 mr-3">
                                <input type="checkbox" id={`randomOrder ${props.questionIndex}`} className="custom-control-input" checked={props.isRandomOrder} onChange={(e) => onSelectRandomOrder()} />
                                <label className="custom-control-label" htmlFor={`randomOrder ${props.questionIndex}`}>
                                    <span className="ml-3" style={{ color: '#666', fontSize: "0.96rem", fontWeight: '400', verticalAlign: '-6px' }}>Randomized Order</span>
                                </label>
                            </div>

                            {/* Validation error */}
                            <div className="col-12">
                                {!props.isValidQuestion && (
                                    <small className="text-danger">
                                        Please complete this question.
                                    </small>
                                )}
                            </div>

                        </div>
                    </div>

                </div>
            </div>
        </div>


    )
}

export default Create_Question_With_Radiobtn


