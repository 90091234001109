import React, { useEffect, useState } from 'react'
import Chart from 'react-apexcharts'
import { useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'
import { Card, CardBody, Button } from 'reactstrap'
import { getActivityReplyGraphs } from '../../../../../actions/activities_actions'
import { getSurveyWordCloud } from '../../../../../actions/activities_reply_actions';
import Loader from "react-loaders";
import LoadingOverlay from 'react-loading-overlay-ts';
import ReactWordcloud from "react-wordcloud";
import { Resizable } from "re-resizable";
import pptxgen from 'pptxgenjs';


export default function GraphCartsWordCloud(props) {
   
    const [wordCloud, setWordCloud] = useState([])
    const [isLoading, setLoading] = useState(true)
    const [isShowWordCloud, setShowWordCloud] = useState(false)
const dispatch = useDispatch()
    const options = {
        rotations: 2,
        rotationAngles: [-90, 0],
        fontFamily: 'Poppins',
    fontSizes:[20,60]
        
    };
    const resizeStyle = {
        display: "flex",
        alignItems: "center",
     justifyContent: "center",
        margin: 'auto',
        border: "none",
        background: "#ffffff",

    };

    const getSurveyQuestionWordCloud = () => {
        setLoading(true)
       
        dispatch(getSurveyWordCloud({ questionId: props.questionId, activityId: props.activityId }))
            .then((response) => {
            
                
                setWordCloud([...response.payload.content.wordCloud])
                setShowWordCloud(true)
                setLoading(false)
            })
            .catch(err => {
                setLoading(false)
            })


    }
  

    // const getSurveyQuestionWordCloud = (questionId, activityId) => {
        
    //     // setLoading(true)
    //     // let questionId = []
    //     // if (isShowQuestion) {
    //     //     questionId = selectedQuestion.map(question => question.questionId)
    //     // }
    //     console.log('activity id ->',props.activityId)
    //     console.log('question id ->',props.questionId)
    //     dispatch(getSurveyWordCloud({ questionId: questionId, activityId: activityId }))
    //         .then((response) => {
    //             console.log('response of wordcloud->', response.payload.content)
                
    //             setWordCloud([...response.payload.content.wordCloud])
    //             setShowWordCloud(true)
    //             setLoading(false)
    //         })
    //         .catch(err => {
    //             setLoading(false)
    //         })


    // }
    // useEffect(()=>{
    //     getSurveyQuestionWordCloud()
    // },[])










    return (
        <>
        <div className='text-center'>
        <Button  onClick={() => getSurveyQuestionWordCloud()}>Generate WordCloud</Button>

        </div>
        <LoadingOverlay tag="div" active={isLoading}
                styles={{
                    overlay: (base) => ({
                        ...base,
                        background: "#fff",
                        opacity: 0.5,
                        paddingTop:'50px'
                    }),
                }}
                spinner={
                    <Loader color="#6c757d" active type="ball-pulse" />
                }/>
                                                    {
        isShowWordCloud === true ? <Resizable
            defaultSize={{
                width: 'auto',
                height: 'auto'
            }}
            style={resizeStyle}
        >
            <div style={{ width: "100%", height: "100%" }}>
                {/* Word cloud */}
               
               {/* {getSurveyQuestionWordCloud()} */}
             
                <ReactWordcloud words={wordCloud} options={options}  />
            </div>
        </Resizable>
        :
        <div style={{height: "150px" }}>

        </div>
    }
   

    </>
    
  )
}
