import axios from 'axios';

import { BACK_SERVER_URL } from '../config/server.js';
import { ACTIVE_PROJECTS_FOR_DASHBOARD, ADMIN_DASHBOARD, RESPONDENT_DASHBOARD } from './types.js';

// ****************  Used in ADMIN & RESEARCHER END in DASHBOARD SECTION *********************

// Returns an array of active projects to display on dashboard
export const getDashboardProjects = (data) => {

    let url = `${BACK_SERVER_URL}api/projects/dashboard`;

    const request = axios.post(url, data)
        .then(response => response.data);

    return {
        type: ACTIVE_PROJECTS_FOR_DASHBOARD,
        payload: request
    }
}

// ****************  Used in ADMIN END in DASHBOARD SECTION *********************

// Returns users and projects stats to display on dashboard
export const adminDashboardDetails = () => {
    
    let url = `${BACK_SERVER_URL}api/users/dashboard`;

    const request = axios.post(url)
        .then(response => response.data);

    return {
        type: ADMIN_DASHBOARD,
        payload: request
    }
}

export const researcherDashboardDetails = () => {
    
    let url = `${BACK_SERVER_URL}api/users/dashboard/researcher`;

    const request = axios.post(url)
        .then(response => response.data);

    return {
        type: ADMIN_DASHBOARD,
        payload: request
    }
}

// ****************  Used in RESPONDENT in DASHBOARD SECTION *********************

// Returns profile status and todays task to display on respondent dashboard
export const respondentDashboardDetails = (userId) => {

    let url = `${BACK_SERVER_URL}api/users/respondent/dashboard/${userId}`;

    const request = axios.post(url)
        .then(response => response.data);

    return {
        type: RESPONDENT_DASHBOARD,
        payload: request
    }
}
// export const getUserTasks = (userId) => {
   
//     let url = `${BACK_SERVER_URL}api/users/tasks/${userId}`;

//     const request = axios.get(url)
//         .then(response => response.data);

//     return {
//         type: RESPONDENT_TASKS,
//         payload: request
//     }
// }
