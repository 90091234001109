import React, { useState, useEffect } from 'react';

import YouTube from 'react-youtube';
import styles from '../../css/styling.module.css';
import { getActivityVideo2, getActivityQuestionImage } from '../../../actions/activities_actions';
import ReactPlayer from 'react-player'
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { PiCheckFatFill } from "react-icons/pi"
import { sendActivityReplyAnswers, sendSharableSurveyReplyAnswers } from '../../../actions/activities_reply_actions';
const Question_With_Checkbox2 = (props) => {
// change this name to original checkbox when scripting is done and remove orignal checkbox file


    // On Checkbox Value change
    const onCheckBoxChange = (value, index) => {
       
        const updatedSelectedOptions = [...selectedOptions]
        if (updatedSelectedOptions.includes(value)) {
            updatedSelectedOptions.splice(updatedSelectedOptions.indexOf(value))
        }
        else { updatedSelectedOptions.push(value) }

        setSelectedOptions(updatedSelectedOptions)
    
       

    // if (props.allContent.content[props.categoryIndex].questions[props.questionIndex].selected) {


    //     if (!props.allContent.content[props.categoryIndex].questions[props.questionIndex].selected.includes(value)) {
    //         props.allContent.content[props.categoryIndex].questions[props.questionIndex].selected.push(value);

    //         //let selectedCheckboxes = props.allContent.pages[props.pageIndex].questions[props.questionIndex].selected




    //     } else {
    //         const index = props.allContent.content[props.categoryIndex].questions[props.questionIndex].selected.indexOf(value);

    //         if (index > -1) {
    //             props.allContent.content[props.categoryIndex].questions[props.questionIndex].selected.splice(index, 1);


    //         }
    //     }
    // } else {

    //     props.allContent.content[props.categoryIndex].questions[props.questionIndex].selected = [value]
    // }

    props.allContent.content[props.categoryIndex].questions[props.questionIndex].selected = updatedSelectedOptions
    props.allContent.content[props.categoryIndex].questions[props.questionIndex].q = props.questionNo;
    props.setContent({ ...props.allContent });

}
    

    // On Change Other Text
    const onChangeOtherText = (e) => {

        props.allContent.pages[props.pageIndex].categories[props.categoryIndex].questions[props.questionIndex].selected.otherText = e.target.value;
        props.setContent({ ...props.allContent });

    }

    const opts = {
        width: '640',
        height: '360',
        playerVars: {
            autoplay: 0
        },
    };
    const params = useParams();
   
    const pageIndex = props.pageIndex
    
    
    const userId = params.UserId
    const dispatch = useDispatch();
    const [selectedOptions, setSelectedOptions] = useState([])
    const [qVideo, setQVideo] = useState({})
    const [qImage, setQImage] = useState({})
    const [isLoading, setIsLoading] = useState(true);

    const catId = props.categoryId
    const questionIndex = props.questionIndex



    useEffect(() => {
        if (params.surveyid || params.ActivityId || params.activityid) {
            dispatch(getActivityVideo2(params.surveyid || params.ActivityId || params.activityid, catId, questionIndex))
                .then((response) => {
                    try {
                    
                        setQVideo(response.payload.content.fileData)
                    } catch (err) { console.log('question video not available') }
                    setIsLoading(false);

                })

           
        }

}, []);
   
   
    const makeLinksOpenInNewTab = (htmlContent) => {
        // Create a DOM element to parse the HTML content
        const tempElement = document.createElement('div');
        tempElement.innerHTML = htmlContent;

        // Find all anchor tags within the parsed HTML content
        const anchorTags = tempElement.getElementsByTagName('a');

        // Iterate through the anchor tags and set the target attribute to '_blank'
        for (const anchorTag of anchorTags) {
            anchorTag.setAttribute('target', '_blank');
            // You can also set 'rel' attribute to 'noopener noreferrer' for security best practices
            anchorTag.setAttribute('rel', 'noopener noreferrer');
        }

        // Return the modified HTML content
        return tempElement.innerHTML;
    }
    return (
        <div className="my-3">
            <div className={styles.card_border} style={props.required && !props.isAnswered ? { borderColor: '#d92550' } : {}}>
                <div className={styles.card_border_body + " pt-4 pb-1"}>
                    <p style={{ color: '#666', fontSize: "1rem", fontWeight: '500', whiteSpace: 'pre-wrap' }} className="mb-0">

                        {/* Required check */}
                        {props.required &&
                            <span className="text-danger mr-1">*</span>
                        }

                        {/* Question text */}
                        {/* {props.questionText} */}
                        {props.questionText &&
                            <span style={{ color: '#000', fontSize: '30px', fontWeight: '500' }} className='mb-0' dangerouslySetInnerHTML={{ __html: makeLinksOpenInNewTab(props.questionText) }} />
                        }

                        {/* Question video */}
                        {props.questionVideo && (
                            <div>

                                <YouTube videoId={props.questionVideo} opts={opts} className='py-4 text-center'></YouTube>

                            </div>
                        )}

                    </p>

                    {/* Question Video */}
                    {
                        props.questionFile &&
                        <>
                            {
                                (typeof props.questionFile === 'string' ? props.questionFile.split('.').pop() === 'mp4' : props.questionFile.name.split('.').pop() === 'mp4') ? (
                                    <div className="mx-3 mt-2 mb-4">

                                        {/* Question Video Display */}
                                        <div className="pt-3">
                                            <div className="position-relative">
                                                {/* <video width={300} controls className="img-fluid- border" style={{ borderRadius: '10px', maxHeight: 500 }}>
                                                    <source src={props.questionFile} type="video/mp4" />
                                                    Your browser does not support the video tag.
                                                </video> */}
                                                {isLoading ? (
                                                    <b>Loading Video...</b>
                                                ) : qVideo.data ? (
                                                    <div style={{ maxWidth: '100%', overflow: 'hidden' }}>
                                                    <ReactPlayer
                                                        url={`data:${qVideo.type};base64,${qVideo.data}`}
                                                        controls
                                                        width ='100%'
                                                    />
                                                    </div>
                                                ) : (
                                                    <b>Loading Video...</b>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                ) :
                                    <div className="mb-3 mt-3">
                                        {/* Image Display */}
                                        {qVideo.data &&

                                            // Image Display
                                            <div>
                                                <div className="position-relative">
                                                    <img src={`data:${qVideo.type} ;base64, ${qVideo.data}`} className={`img-fluid border ${styles.banner_image}`} style={{ borderRadius: '10px' }} alt="img" />
                                                </div>
                                            </div>
                                        }
                                    </div>

                            }
                        </>


                    }



                    {/* Checkboxes */}
                    <div className="row mt-4 mx-1">

                        {props.options.map((option, index) => {

                            return <div
                            className={`${styles.card_border} col-12 d-flex  px-4 my-2`}
                            style={{
                              backgroundColor: selectedOptions.includes(option) ? '#E8F6FF' : '',
                              border: selectedOptions.includes(option) ? '2px solid #0069AB' : '',
                            }}
                          >
                            <label
                              className='mt-2'
                              htmlFor={props.id + index}
                              onClick={() => onCheckBoxChange(option, index)}
                              style={{ cursor: 'pointer' }}
                            >
                              <PiCheckFatFill
                                className='mr-4 mt-2'
                                size={20}
                                style={{ color: selectedOptions.includes(option) ? '#1575E5' : '#C6C6C6' }}
                              />
                              <span style={{ color: '#000', fontSize: '18px', fontWeight: '500' }}>{option}</span>
                            </label>
                            <input
                              type="checkbox"
                              id={props.id + index}
                              className="custom-control-input"
                              value={option}
                              onChange={() => {}}
                              checked={props.selected && props.selected.includes(option)}
                              style={{ display: 'none' }} // Hide the actual checkbox
                            />
                          </div>
                          
                          
                        
                        
                        
                        
                            
                            // <div className={`${styles.card_border} col-12 d-flex  px-4 my-2`} style={{ backgroundColor: selectedOptions.includes(option) ? '#E8F6FF' : '', border: selectedOptions.includes(option) ? '2px solid #0069AB' : '' }}>
                            //      <PiCheckFatFill className='mr-4 mt-2' size={20} style={{color:selectedOptions.includes(option) ? '#1575E5':'#C6C6C6'}}/>
                            //     <label className='mt-2' htmlFor={props.id + index}><span style={{ color: '#000', fontSize: '18px', fontWeight: '500', cursor: 'pointer' }}> {option}</span></label>
                               
                            //     <input type="checkbox" id={props.id + index} className="custom-control-input" value={option} onChange={e => onCheckBoxChange(e.target.value, index)} checked={props.selected && props.selected.includes(option)} />
                            // </div>




                            // <div key={index} className="col-md-6 col-12 mb-4">

                            //     {/* Checkbox */}
                            //     <div className="custom-checkbox custom-control">
                            //         <input type="checkbox" id={props.id + index} className="custom-control-input" value={option} onChange={e => onCheckBoxChange(e.target.value,index)} checked={props.selected && props.selected.includes(option)} />

                            //         <label className="custom-control-label" htmlFor={props.id + index}>
                            //             <span className="ml-3" style={{ color: '#666', fontSize: "0.96rem", fontWeight: '400', verticalAlign: '-6px' }}>{option}</span>
                            //         </label>
                            //     </div>

                            // </div>

                        })}

                        {/* Other */}
                        {props.isOtherAllowed && (
                            <div className="col-md-6 col-12 mb-4">

                                <div className="input-group">

                                    <div className="input-group-prepend align-self-center">

                                        {/* Other Checkbox */}
                                        <div className="custom-checkbox custom-control">
                                            <input type="checkbox" id={props.id} name={props.name} className="custom-control-input" value="other" onChange={e => onCheckBoxChange(e.target.value)} checked={props.selected.includes("other")} />
                                            <label className="custom-control-label" htmlFor={props.id}>
                                                <span className="ml-3" style={{ color: '#666', fontSize: "0.96rem", fontWeight: '400', verticalAlign: '-6px' }}>Other</span>
                                            </label>
                                        </div>

                                    </div>

                                    {/* Other Text */}
                                    {props.selected.includes("other") && (
                                        <div className="form-group mx-3 mb-0">
                                            <input className="form-control" type="text" value={props.otherText} onChange={onChangeOtherText} placeholder="Write here" />
                                        </div>
                                    )}

                                </div>
                            </div>
                        )}

                    </div>
                   
                    {/* Required Answer error */}
                    {props.required && !props.isAnswered && (
                        <div id={`error-msg-${props.id}`}>
                            <div className="my-2">
                                <div className="alert alert-danger mb-0">
                                    Please answer this question.
                                </div>
                            </div>
                        </div>
                    )}

                </div>
            </div>
        </div>
    )

}

export default Question_With_Checkbox2;